
import { AppEvents } from "@/control/app-events";
import { defineComponent, nextTick } from "vue";
import { useVModel } from "@/utils/vmodel";
import { FocusTrap } from "@/utils/focus-trap";
import { AuthController } from "@/control/auth";

export default defineComponent({
  name: "OrganizationSettingsModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      selectedOrganization: AuthController.SelectedOrganization,
      selectedOrganizationName: AuthController.SelectedOrganizationMembership ? AuthController.SelectedOrganizationMembership.name : "",
      isOrgOwner: !!AuthController.SelectedOrganizationMembership && AuthController.SelectedOrganizationMembership.owner,
      isOrgAdmin: !!AuthController.SelectedOrganizationMembership && AuthController.SelectedOrganizationMembership.admin,
    };
  },
  methods: {
    open: function () {
      this.displayStatus = true;
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    clickOnEnter: function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        event.target.click();
      }
    },

    onAuthChanged: function () {
      this.selectedOrganization = AuthController.SelectedOrganization;
      this.selectedOrganizationName = AuthController.SelectedOrganizationMembership ? AuthController.SelectedOrganizationMembership.name : "";
      this.isOrgOwner = !!AuthController.SelectedOrganizationMembership && AuthController.SelectedOrganizationMembership.owner;
      this.isOrgAdmin = !!AuthController.SelectedOrganizationMembership && AuthController.SelectedOrganizationMembership.admin;
    },

    openSelectOrganization: function () {
      AppEvents.Emit("organization-select-open");
    },

    openCreateOrganization: function () {
      AppEvents.Emit("organization-create-open");
    },

    openLeaveOrganization: function () {
      AppEvents.Emit("organization-leave-open");
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("organization-settings-open", this.$options.openH);

    this.$options.onAuthChangedH = this.onAuthChanged.bind(this);
    AppEvents.AddEventListener(
      "auth-status-changed",
      this.$options.onAuthChangedH
    );

    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("organization-settings-open", this.$options.openH);
    AppEvents.RemoveEventListener(
      "auth-status-changed",
      this.$options.onAuthChangedH
    );
    this.$options.focusTrap.destroy();
  },
  watch: {
    display: function () {
      if (this.display) {
        this.$options.focusTrap.activate();
        nextTick(() => {
          this.$el.focus();
        });
      } else {
        this.$options.focusTrap.deactivate();
      }
    },
  },
});
